.ant-menu{
    background: #27374D;
    color: #ffffff;
}

.ant-layout-sider-children{
    background: #27374D;
}
.ant-menu-submenu.ant-menu-submenu-inline {
    background: #526D82;
}

.ant-menu-dark .ant-menu-item-selected{
    background: #159895;
    color: #ffffff;
}

.ant-menu-item{
    background: #526D82;
    color: #ffffff;
}